<template>
  <div class="medical-record">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-row class="mt-3">
      <v-col cols="12">
        <span class="heading-2 capitalize">{{ name }}</span>
      </v-col>
      <v-col v-for="card in orders" :key="card.id" cols="12">
        <MonitoringOrderDetailed
          :date="card.date"
          :item="card"
          :name="card.display"
          :showChange="card.status === 'active'"
          :showStop="card.status === 'active'"
          :time="card.time"
          :unit="card.unit"
          :value="card.value"
          @onChange="onEditOrder(card)"
          @onStop="onStopOrder(card)"
        />
      </v-col>
    </v-row>
    <AddBloodPressureOrder :isStatusActive="true" />
    <AddTemperatureOrder :isStatusActive="true" />
    <AddOxygenSaturationOrder :isStatusActive="true" />
    <AddRespiratoryRateOrder :isStatusActive="true" />
    <AddHeartRateOrder :isStatusActive="true" />
    <AddBloodGlucoseOrder :isStatusActive="true" />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import AddBloodGlucoseOrder from "@/components/VisitNotes/bloodGlucoseOrder/Add";
import AddBloodPressureOrder from "@/components/VisitNotes/bloodPressureOrder/Add";
import AddHeartRateOrder from "@/components/VisitNotes/heartRateOrder/Add";
import AddOxygenSaturationOrder from "@/components/VisitNotes/oxygenSaturationOrder/Add";
import AddRespiratoryRateOrder from "@/components/VisitNotes/respiratoryRateOrder/Add";
import AddTemperatureOrder from "@/components/VisitNotes/temperatureOrder/Add";
import { OBSERVATION_ORDERS_ANY_ADDED } from "@/eventBuses/monitoringEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useBloodGlucoseOrderStore } from "@/pinia-store/bodyGlucoseOrder";
import { useBloodPressureOrderStore } from "@/pinia-store/bodyPresureOrder";
import { useBodyTemperatureOrderStore } from "@/pinia-store/bodyTemperatureOrder";
import { useHeartRateOrderStore } from "@/pinia-store/heartRateOrder";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";
import { useOxygenSaturationOrderStore } from "@/pinia-store/oxygenStaturationOrder";
import { useRespiratoryRateOrderStore } from "@/pinia-store/respiratoryRateOrder";
import { getObservationNameByKind, observationsEnum, observationTypes } from "@/utils/observationTypes";
import MonitoringOrderDetailed from "@/views/Practitioner/PractitionerMonitoringListKindOrders/MonitoringOrderDetailed";

export default {
  name: "PractitionerMonitoringListKindOrders",
  components: {
    AddBloodGlucoseOrder,
    AddHeartRateOrder,
    AddRespiratoryRateOrder,
    AddOxygenSaturationOrder,
    AddTemperatureOrder,
    AddBloodPressureOrder,
    MonitoringOrderDetailed,
  },
  data() {
    return {
      loading: false,
      tab: "medical-record",
      search: "",
      searchActive: false,
      recentSearches: [],
      searchResult: [],
      searchLoading: false,
      noResults: false,
      obType: null,
      addNoteDialog: false,
      noteMode: "",
      editId: null,
      orders: [],
    };
  },
  computed: {
    ...mapState(useMedicalRecordStore, ["patient", "encounters"]),
    ...mapState(useAuthStore, ["uid", "role"]),
    name() {
      return `${getObservationNameByKind(this.$route.params.kind)} order`;
    },
    cards() {
      return observationTypes;
    },
  },

  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useBloodPressureOrderStore, { toggleBPFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodPressureOrderStore, { fillBPForm: "fillForm" }),
    ...mapActions(useHeartRateOrderStore, { fillHeartRateOrderForm: "fillForm" }),
    ...mapActions(useHeartRateOrderStore, { toggleHeartRateOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBodyTemperatureOrderStore, { fillBodyTemperatureOrderForm: "fillForm" }),
    ...mapActions(useBodyTemperatureOrderStore, { toggleBodyTemperatureOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useRespiratoryRateOrderStore, { fillRespiratoryRateOrderForm: "fillForm" }),
    ...mapActions(useRespiratoryRateOrderStore, { toggleRespiratoryRateOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodGlucoseOrderStore, { fillBloodGlucoseOrderForm: "fillForm" }),
    ...mapActions(useBloodGlucoseOrderStore, { toggleBloodGlucoseOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useOxygenSaturationOrderStore, { fillOxygenSaturationOrderForm: "fillForm" }),
    ...mapActions(useOxygenSaturationOrderStore, { toggleOxygenSaturationOrderFormOpened: "toggleFormOpened" }),

    onEditOrder(item) {
      switch (item.kind) {
        case observationsEnum.bloodPressure: {
          this.fillBPForm({ ...item });
          this.toggleBPFormOpened();
          break;
        }
        case observationsEnum.heartRate: {
          this.fillHeartRateOrderForm({ ...item });
          this.toggleHeartRateOrderFormOpened();
          break;
        }
        case observationsEnum.bodyTemperature: {
          this.fillBodyTemperatureOrderForm({ ...item });
          this.toggleBodyTemperatureOrderFormOpened();
          break;
        }
        case observationsEnum.respiratoryRate: {
          this.fillRespiratoryRateOrderForm({ ...item });
          this.toggleRespiratoryRateOrderFormOpened();
          break;
        }
        case observationsEnum.bloodGlucose: {
          this.fillBloodGlucoseOrderForm({ ...item });
          this.toggleBloodGlucoseOrderFormOpened();
          break;
        }
        case observationsEnum.oxygenSaturation: {
          this.fillOxygenSaturationOrderForm({ ...item });
          this.toggleOxygenSaturationOrderFormOpened();
          break;
        }
      }
    },
    async onStopOrder(order) {
      this.startSave();
      await ServiceRequestApi.destroyObservationOrder(order.id);
      this.endSave();
      await this.load();
    },
    async load() {
      this.loading = true;
      const { kind, patientId } = this.$route.params;
      this.orders = await ServiceRequestApi.getAll({ patientId, kind: [kind] });
      this.loading = false;
    },
  },
  beforeDestroy() {
    this.$root.$off(OBSERVATION_ORDERS_ANY_ADDED, this.load);
  },
  async mounted() {
    await this.load();
    this.$root.$on(OBSERVATION_ORDERS_ANY_ADDED, this.load);
  },
};
</script>

<style lang="scss" scoped>
.medical-record {
  background: #fafafa;
  padding: 0 30px;
}

.tab_item {
  letter-spacing: normal;
  color: #25233a;
  font-size: 14px;
  opacity: 0.4;

  &.v-tab--active {
    color: var(--primary);
    opacity: 1;
  }
}

.healthdata__add-btn {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  box-shadow: none;
  text-transform: capitalize;
}

.healthdata__item.v-list-item {
  transition: 0.3s ease;

  &:hover {
    background-color: rgba(47, 207, 111, 0.08);
    color: #2fcf6f;
    cursor: pointer;
  }
}
</style>
