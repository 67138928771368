<template>
  <div :class="{ unread: isHighlight }" class="item mb-2">
    <div class="content">
      <p class="text-title">{{ name }}</p>
      <p class="text--secondary">{{ toDate(item.startDate) }}-{{ toDate(item.endDate) }}</p>
      <p class="text-subtitle pb-0 mb-0 pt-2">Frequency</p>
      <p class="text--secondary pt-0">{{ item.patientInstruction }}</p>
      <template v-if="item.note">
        <p class="text-subtitle mb-0 pt-2">Notes</p>
        <p class="text--secondary pt-0 pb-0">{{ item.note }}</p>
      </template>
      <div class="order">
        <p class="text-subtitle pt-2">Ordered by</p>
        <p class="text--secondary">
          Dr. {{ item.practitioner.firstName || "" }} {{ item.practitioner.lastName || "" }}
        </p>
      </div>
    </div>
    <div class="actions">
      <v-btn v-if="showChange" color="primary" depressed @click="$emit('onChange')"> Change</v-btn>
      <v-btn v-if="showStop" color="error" depressed outlined @click="$emit('onStop')"> Stop</v-btn>
      <v-btn v-if="showViewDetails" color="primary" outlined @click="$emit('onViewDetails')"> View details</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";
import { useAuthStore } from "@/pinia-store/auth";
import { getObservationNameByKind } from "@/utils/observationTypes";

export default {
  name: "MonitoringOrderDetailed",
  components: {},
  props: {
    isHighlight: {
      default: false,
      type: Boolean,
    },
    showEdit: {
      default: false,
      type: Boolean,
    },
    showStop: {
      default: false,
      type: Boolean,
    },
    showChange: {
      default: false,
      type: Boolean,
    },
    showViewDetails: {
      default: false,
      type: Boolean,
    },
    showDelete: {
      default: false,
      type: Boolean,
    },
    showAdd: {
      default: true,
      type: Boolean,
    },
    showView: {
      default: true,
      type: Boolean,
    },
    item: {
      default: () => ({}),
      type: Object,
    },
    value: {
      default: "",
      type: String,
    },
    unit: {
      default: "",
      type: String,
    },
    date: {
      default: "",
      type: String,
    },
    time: {
      default: "",
      type: String,
    },
  },
  computed: {
    ...mapState(useAuthStore, ["userIsPatient"]),
    name() {
      return getObservationNameByKind(this.item.kind);
    },
  },
  methods: {
    toDate(date) {
      return this.$moment(date).format(timeDisplayFormat);
    },
    onAdd() {
      this.$emit("onAdd");
    },
    onDelete() {
      this.$emit("onDelete");
    },
    onEdit() {
      this.$emit("onEdit");
    },
    onView() {
      this.$emit("onView");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  padding: 24px 26px;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  min-width: 600px;
  max-width: 80vw;

  .title {
    padding-bottom: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #33343e;
  }

  .card-details {
    border: 1px solid #e7e8f2;
    padding: 16px;
  }
}

.item {
  display: flex;
  background: white;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 18px 20px 24px;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;

  &.unread {
    background: rgba(239, 246, 255, 0.4);
  }

  .unread-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--primary);
    padding-left: 8px;
  }

  .content {
    p {
      margin-bottom: 0;
    }

    .text-title {
      color: rgba(51, 52, 62, 0.4);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #33343e;
      opacity: 0.9;
    }

    .text-subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #25233a;
      mix-blend-mode: normal;
      opacity: 0.5;
    }

    .quantity {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #33343e;
      opacity: 0.9;
    }

    .order {
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &:first-of-type {
          font-weight: 400;
        }
      }
    }

    .date {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #33343e;
      opacity: 0.4;
    }
  }

  .actions {
    display: flex;
    gap: 16px;
    align-self: flex-start;

    button {
      text-transform: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .item {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .actions {
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
</style>
